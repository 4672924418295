import { Icon } from "@afterpaytouch/core";
import className from "classnames/bind";
import React from "react";

import { ChunkedNav, GenericNavItem } from "@/interfaces/navigation-interface";

import { Locale } from "@/types/locale";

import { AppLogo } from "../navigation";
import { MobileNavigationItem } from "./mobile-navigation-item";
import styles from "./mobileNavigation.module.scss";

const cx = className.bind(styles);

export interface Props {
    navigationItems: ChunkedNav;
    isMobileMenuActive: boolean;
    footerNavigationItems: Array<Array<GenericNavItem>>;
    locale: Locale;
    onToggleMenuActive: (isActive: boolean) => void;
}

export const ArcadeMobileNavigation: React.FC<Props> = (props: Props) => {
    const { navigationItems, isMobileMenuActive, onToggleMenuActive } = props;
    const mobileNavigationContainerClassName = cx({
        [styles.mobileNavigationContainerArcade]: true,
        [styles.mobileNavigationContainerArcadeActive]: isMobileMenuActive,
    });

    const mobileNavigationContentClassName = cx({
        [styles.mobileNavigationContentArcade]: true,
    });

    const onToggleMenu = () => {
        onToggleMenuActive(!isMobileMenuActive);
    };

    return (
        <div className={mobileNavigationContainerClassName}>
            <div className={styles.mobileNavigationBarArcade}>
                <div className={styles.mobileNavigationBarLogoArcade}>
                    <AppLogo />
                </div>
                <div onClick={onToggleMenu} className={styles.mobileNavigationCloseIconArcade}>
                    {isMobileMenuActive && <Icon.Close size="Small" />}
                </div>
            </div>
            <div className={mobileNavigationContentClassName}>
                <ul>
                    {navigationItems.map(([navItem, subNavItems], index) => (
                        <MobileNavigationItem
                            key={`${navItem.id}-${index}`}
                            text={navItem.text}
                            url={navItem.link}
                            categoryId={navItem.categoryId}
                            subMenuItemChunks={subNavItems}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};
