import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

import LocaleSwitcher, { Props as LocaleSwitcherProps } from "@/components/LocaleSwitcher";

import { ChunkedNav } from "@/interfaces/navigation-interface";

import styles from "./footer.module.scss";

type Props = {
    navigationItems: ChunkedNav;
} & LocaleSwitcherProps;
export const ArcadeFooterLocal: React.FC<Props> = ({ locales, currentLocale, navigationItems }) => {
    const { t } = useTranslation();

    const links = navigationItems.map(([titleNavItem, children]) => (
        <div key={titleNavItem.id} className={styles.col}>
            <div className={styles.colHeader}>{titleNavItem.text}</div>
            <ul>
                {children.flatMap((columns) =>
                    columns.map((child) => (
                        <li key={child.id}>
                            {child.link ? (
                                <Link prefetch={false} href={child.link}>
                                    {child.text}
                                </Link>
                            ) : (
                                child.text
                            )}
                        </li>
                    ))
                )}
            </ul>
        </div>
    ));

    return (
        <>
            {links}
            <div className={styles.localeSwitcher}>
                <div className={styles.colHeader}>{t("footer.localeSelect")}</div>
                <LocaleSwitcher locales={locales} currentLocale={currentLocale} />
            </div>
        </>
    );
};
